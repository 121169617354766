export function timeSince(timestamp) {
  if (!timestamp || (!timestamp._seconds && !timestamp._nanoseconds)) return "";

  const milliseconds =
    timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000;

  const elapsed = Math.floor((new Date() - new Date(milliseconds)) / 1000);
  const intervals = [
    { label: "day", seconds: 86400 },
    { label: "hour", seconds: 3600 },
    { label: "minute", seconds: 60 },
    { label: "second", seconds: 1 },
  ];

  for (const interval of intervals) {
    const count = Math.floor(elapsed / interval.seconds);
    if (count > 0)
      return `${count} ${interval.label}${count !== 1 ? "s" : ""} ago`;
  }
  return "";
}

export function formatTimestamp(timestamp) {
  if (!timestamp || (!timestamp._seconds && !timestamp._nanoseconds)) return "";

  const milliseconds =
    timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000;
  const date = new Date(milliseconds);

  const options = { year: "numeric", month: "long", day: "numeric" };
  return date.toLocaleDateString(undefined, options);
}

export function debounce(func, delay) {
  let timeoutId;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
}

export function areObjectsEqual(obj1, obj2) {
  if (typeof obj1 !== "object" || typeof obj2 !== "object")
    return obj1 === obj2;

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false;

  for (const key of keys1) {
    const value1 = obj1[key];
    const value2 = obj2[key];

    if (!areObjectsEqual(value1, value2)) return false;
  }

  return true;
}
