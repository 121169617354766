import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { HiDotsHorizontal } from "react-icons/hi";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Menu, Transition } from "@headlessui/react";
import { RiSendPlaneFill } from "react-icons/ri";
import { BsFillHddStackFill } from "react-icons/bs";
import { ImBin } from "react-icons/im";
import LeadPopup from "./LeadPopup";
import axios from "axios";
import SendEmail from "./SendEmail";
import { toast } from "react-hot-toast";
import useSWR, { useSWRConfig } from "swr";
import Badge from "../Badge";
import useAuth from "../../hooks/useAuth";
import { useConfirmation } from "../ConfirmationService";
import { PiCopySimpleLight } from "react-icons/pi";
import { FiCheckCircle } from "react-icons/fi";
import copy from "copy-to-clipboard";
import Avatar from "../Avatar";
import { CgMoveRight } from "react-icons/cg";
import Moveto from "./Moveto";
import { formatTimestamp } from "../../utils/common";



const LeadCard = ({ lead, email, loadingLeads, leadsMutate }) => {

  const [isOpenEmailPopup, setOpenEmailPopup] = useState(false);
  const [isOpenPopup, setOpenPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isOpenMoveto, setOpenMoveto] = useState(false);
  const {
    data: leadData,
  } = useSWR(email && `/crm/${email}?source=${lead?.source}`);

  const memoizedData = useMemo(() => {
    return { ...leadData?.[email], } ?? null;
  }, [email, leadData]);

  const {
    hippoTrust,
    updated_at


  } = memoizedData || {};
  const hippoTrustLevel = hippoTrust?.level;

  const confirm = useConfirmation();
  const { mutate } = useSWRConfig();
  const { user } = useAuth();
  const [copiedField, setCopiedField] = useState(null);
  const [avatarUrl, setAvatarUrl] = useState(null);

  const handleCopy = (field, e) => {
    e.stopPropagation();
    const textToCopy = field === "email" ? lead?.email : lead?.userId;
    copy(textToCopy);
    setCopiedField(field);
    setTimeout(() => setCopiedField(null), 1000);
  };

  // Function to fetch avatar data
  const fetchAvatarData = useCallback(async () => {
    if (lead?.email) {
      try {
        const url = `/crm/${lead?.email}?source=${lead?.source}`;
        const response = await axios.get(url, {
          headers: { "x-api-key": process.env.REACT_APP_X_API_KEY_CRM || "" },
        });
        setAvatarUrl(response.data[lead?.email]?.avatarUrl || null);
      } catch (error) {
        console.error("Error fetching avatar data:", error);
        setAvatarUrl(null);
      }
    }
  }, [lead?.email, lead?.source]);

  // Call fetchAvatarData only when needed
  useEffect(() => {
    fetchAvatarData();
  }, [fetchAvatarData]);
  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: lead.email,
    data: {
      type: "Lead",
      lead,
    },
    disabled: loadingLeads?.has(lead?.email) || loading,
  });


  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  const deleteLead = async () => {
    const confirmDelete = await confirm({
      title: "Confirm Lead Deletion",
      description:
        "You are about to permanently delete this lead. This action will erase the lead's information from Firestore and SendGrid. If an account has been created with this email, it will also be removed. Please note that this action is irreversible.",
      actionLabel: "Delete",
      danger: true,
    });

    if (!confirmDelete) return;
    setLoading(true);

    if (lead?.userId) {
      const response = await deleteGuestProfile(lead?.userId);
      if (!response.success) return setLoading(false);
    }

    axios
      .delete(`/crm`, {
        data: { email: lead?.email, type: "lead", source: lead?.source },
        headers: { "x-api-key": process.env.REACT_APP_X_API_KEY_CRM || "" },

      })
      .then(async () => await leadsMutate())
      .catch((err) => {
        const error =
          err?.response?.data?.message || err?.response?.data?.error;
        if (error) toast.error(error);
        else toast.error("Something went wrong!");
      })
      .finally(() => setLoading(false));
  };

  const deleteGuestProfile = async (userId) => {
    return axios
      .delete(`/guest?uid=${userId}`, {
        headers: { Authorization: user?.accessToken || "" },
      })
      .then(() => ({ success: true }))
      .catch((err) => {
        if (err?.response?.status === 404) return { success: true };
        const error =
          err?.response?.data?.message || err?.response?.data?.error;
        if (error) toast.error(error);
        else toast.error("Something went wrong!");
        return { success: false };
      });
  };

  const handleDataEnrich = () => {
    setLoading(true);
    axios
      .post(
        `/crm/verify`,
        { email: lead?.email, source: lead?.source },
        {
          headers: { "x-api-key": process.env.REACT_APP_X_API_KEY_CRM || "" },
        }
      )
      .then(async () => {
        await mutate(`/crm/${lead?.email}`);
        toast.success("Data enrich successful!");
      })
      .catch((err) => {
        const error =
          err?.response?.data?.message || err?.response?.data?.error;
        if (error) toast.error(error);
        else toast.error("Something went wrong!");
      })
      .finally(() => setLoading(false));
  };

  // While dragging
  if (isDragging) {
    return (
      <div
        ref={setNodeRef}
        style={style}
        className="p-2 h-[100px] min-h-[100px] items-center flex rounded-lg border border-primary cursor-grab relative"
      />
    );
  }


  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className={`p-2 min-h-[170px] rounded-lg border-[0.75px] border-[#D9DDE4]
          ${lead?.status === "QUALIFIED" ? "bg-[#E1E2FE] text-black" :
          lead?.status === "ENGAGED" ? "bg-[#C9C9FC] text-black" :
            lead?.status === "SALE" ? "bg-[#ABA9F8] text-black" :
              lead?.status === "ABANDONED" ? "bg-[#ABA9F8] text-black" :
                lead?.status === "NEW"
                  ? (hippoTrustLevel === "Low" ? "bg-red-100 text-black" :
                    hippoTrustLevel === "Medium" ? "bg-yellow-100 text-black" :
                      hippoTrustLevel === "High" ? "bg-green-100 text-black" :
                        "bg-gray-100 text-black")
                  : ""} 
                shadow-sm hover:ring-1 hover:ring-inset hover:ring-primary cursor-grab relative whitespace-pre-wrap break-words 
                ${loadingLeads?.has(lead?.email) || loading ? "animate-pulse" : ""}
              `}
    >

      {/* card content */}
      <div
        onClick={() => setOpenPopup(true)}
        className="my-auto min-h-[100%] w-full  overflow-x-hidden whitespace-pre-wrap break-words relative"
      >
        <div className="mt-1 flex flex-col gap-2">
          <p className="text-lg font-semibold w-[95%] ">{lead?.fullName} </p>

          <p className="text-xs flex ">
            {lead?.email}
            <span className="px-1"
              onClick={(e) => handleCopy("email", e)}
              style={{ cursor: "pointer" }}>
              {copiedField === "email" ? (
                <FiCheckCircle className="text-green-500" />
              ) : (
                <PiCopySimpleLight />
              )}
            </span>
          </p>
          <p className="text-xs flex whitespace-pre-wrap break-words">
            {lead?.phone ? (
              <>
                {lead.phone}
                <span
                  className="px-1"
                  onClick={(e) => handleCopy("clientId", e)}
                  style={{ cursor: "pointer" }}
                >
                  {copiedField === "clientId" ? (
                    <FiCheckCircle className="text-green-500" />
                  ) : (
                    <PiCopySimpleLight />
                  )}
                </span>
              </>
            ) : (
              null
            )}
          </p>

          <p className="text-xs flex  whitespace-pre-wrap break-words">

            <span className="text-[10px]">{updated_at && formatTimestamp(updated_at)} </span>
          </p>
        </div>
        <div className="flex justify-end items-center gap-2">

          {lead?.subscription && (
            <Badge
              className={` text-white mt-4
             ${lead.status === "NEW" ?
                  hippoTrustLevel === "Low" ? "bg-red-500" :
                    hippoTrustLevel === "Medium" ? "bg-yellow-400" :
                      hippoTrustLevel === "High" ? "bg-green-500" :
                        "bg-transparent" : "bg-primary"
                }`}
              lead={lead}
            />

          )}
          <div className=" hidden md:block">
            <Avatar
              src={avatarUrl}
              alt="Profile"
              className="w-10 h-10 border mr-2 mt-3 border-border-2 active:ring-1 ring-primary"

            />
          </div>

          <div className="">
            <div className="w-10  md:hidden block mt-2  h-10  ">
              <Avatar
                src={avatarUrl}
                alt="Profile"
                className="w-6 h-6 border border-border-2 mt-2  active:ring-1 ring-primary"
              />
            </div>
          </div>


        </div>
      </div>

      {(
        <Menu
          as="div"
          className={`absolute right-1 top-6 -translate-y-1/2  z-20  ${loading ? "animate-pulse" : ""
            }`}
        >
          <Menu.Button className="inline-flex w-full justify-center rounded  opacity-90 hover:opacity-100 px-1 font-medium focus:outline-none focus:ring-1 focus:ring-offset-1 ring-primary">
            <HiDotsHorizontal
              className="h-5 w-5 "
              aria-hidden="true"
            />
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-6 top-3 w-32  divide-y divide-border-border-1 rounded-md bg-background-4 shadow-lg focus:outline-none border border-border-1">
              <div className="px-1 py-1 ">
                <Menu.Item>
                  {() => (
                    <button
                      onClick={() => setOpenEmailPopup(true)}
                      className={`hover:bg-background-3 hover:text-white dark:text-white ${lead?.status === "ENGAGED" || lead?.status === "SALE" ? "text-black dark:text-white" : ""
                        } group flex gap-1 w-full items-center rounded-md px-2 py-2 text-xs`}
                    >
                      <RiSendPlaneFill />
                      <span>Send Email</span>
                    </button>
                  )}
                </Menu.Item>

                <Menu.Item>
                  {() => (
                    <button
                      onClick={handleDataEnrich}
                      className={`hover:bg-background-3 hover:text-white dark:text-white ${lead?.status === "ENGAGED" || lead?.status === "SALE" ? "text-black dark:text-white" : ""
                        } group flex gap-1 w-full items-center rounded-md px-2 py-2 text-xs`}
                    >
                      <BsFillHddStackFill />
                      <span>Enrich Data</span>
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {() => (
                    <button
                      onClick={() => setOpenMoveto(true)}
                      className={`hover:bg-background-3 hover:text-white dark:text-white ${lead?.status === "ENGAGED" || lead?.status === "SALE" ? "text-black dark:text-white" : ""
                        } group flex gap-1 w-full items-center rounded-md px-2 py-2 text-xs`}
                    >
                      <CgMoveRight className="h-5 w-5" />
                      <span>Move to</span>
                    </button>
                  )}
                </Menu.Item>

                <Menu.Item>
                  {() => (
                    <button
                      disabled={loading}
                      onClick={deleteLead}
                      className={` hover:bg-background-3 text-red-light-txt group flex gap-1 w-full items-center rounded-md px-2 py-2 text-xs`}
                    >
                      <ImBin />
                      <span>Delete</span>
                    </button>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      )}
      {isOpenMoveto &&
        <Moveto email={lead?.email}
          source={lead?.source}
          leadsMutate={leadsMutate}
          isOpen={isOpenMoveto}
          close={() => setOpenMoveto(false)} />
      }
      {isOpenPopup && (
        <LeadPopup
          isOpen={isOpenPopup}
          close={() => setOpenPopup(false)}
          email={lead?.email}
          phone={lead?.phone}
          source={lead?.source}
          leadsMutate={leadsMutate}
          handleDataEnrich={handleDataEnrich}
        />
      )}
      {isOpenEmailPopup && (
        <SendEmail
          isOpen={isOpenEmailPopup}
          close={() => setOpenEmailPopup(false)}
          lead={lead}
        />
      )}
    </div>
  );
};

export default LeadCard;
