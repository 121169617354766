import { boardColumns } from "../constants/board";

const Skeleton = ({ type }) => {
  if (type === "board") {
    return (
      // Update grid cols based on board length
      <div
        className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 w-full`}
      >
        {/* Slice the boardColumns array to only take the first 4 columns */}
        {boardColumns.slice(0, 4).map((col, index) => (
          <div
            key={col.id}
            className="bg-background-4 rounded-lg border border-border-1 px-2"
          >
            <p key={col.id} className="flex flex-col h-[66.5vh]">
              <p className="my-4 py-2 px-8 bg-background-3 rounded max-w-fit animate-pulse"></p>
              <div className="flex flex-col gap-4">
                <p className="bg-background p-2.5 min-h-[100px] rounded-lg border border-border-1 shadow-sm cursor-grab relative animate-pulse">
                  <p className="py-2 px-16 xl:px-20 bg-background-3 rounded max-w-fit animate-pulse"></p>
                  <p className="py-2 px-8 xl:px-12 bg-background-3 rounded-sm max-w-fit animate-pulse absolute bottom-2.5 right-2.5"></p>
                </p>
                <p className="bg-background p-2.5 min-h-[100px] rounded-lg border border-border-1 shadow-sm cursor-grab relative animate-pulse">
                  <p className="py-2 px-16 xl:px-20 bg-background-3 rounded max-w-fit animate-pulse"></p>
                  <p className="py-2 px-8 xl:px-12 bg-background-3 rounded-sm max-w-fit animate-pulse absolute bottom-2.5 right-2.5"></p>
                </p>
                <p className="bg-background p-2.5 min-h-[100px] rounded-lg border border-border-1 shadow-sm cursor-grab relative animate-pulse">
                  <p className="py-2 px-16 xl:px-20 bg-background-3 rounded max-w-fit animate-pulse"></p>
                  <p className="py-2 px-8 xl:px-12 bg-background-3 rounded-sm max-w-fit animate-pulse absolute bottom-2.5 right-2.5"></p>
                </p>
                <p className="bg-background p-2.5 min-h-[100px] rounded-lg border border-border-1 shadow-sm cursor-grab relative animate-pulse">
                  <p className="py-2 px-16 xl:px-20 bg-background-3 rounded max-w-fit animate-pulse"></p>
                  <p className="py-2 px-8 xl:px-12 bg-background-3 rounded-sm max-w-fit animate-pulse absolute bottom-2.5 right-2.5"></p>
                </p>

              </div>
            </p>
            <div className="flex items-center gap-2">
              <p className="mb-3 py-2 px-2 bg-background-3 rounded max-w-fit animate-pulse"></p>
              <p className="mb-3 py-2 px-8 bg-background-3 rounded max-w-fit animate-pulse"></p>
            </div>
          </div>
        ))}
      </div>
    );


  } else if (type === "dashboard") {
    return (
      <div className="grid grid-cols-2 md:grid-cols-5 gap-5">
        {[10, 16, 12, 14, 12].map((i, index) => (
          <div
            key={`${i}__dashboard_skeleton__${index}`}
            className={`py-5 px-4 rounded-xl shadow-custom-light dark:shadow-custom-dark ${index === 4 ? "col-span-2 md:col-span-1" : ""
              }`}
          >
            <p
              className={`bg-background-3 rounded py-4 animate-pulse w-${i}`}
            ></p>
            <p
              className={`mt-5 max-w-fit bg-background-3 rounded py-2 animate-pulse px-12`}
            ></p>
          </div>
        ))}
      </div>
    );
  } else if (type === "waitlist") {
    return (
      <div className="flex flex-col gap-3">
        <div className="flex flex-col space-y-4 p-4 border border-background-3 rounded-md shadow-sm animate-pulse">
          <div className="flex items-center justify-between">
            <div className="h-5 w-32 bg-background-3 rounded"></div>
            <div className="h-5 w-24 bg-background-3 rounded"></div>
            <div className="h-5 w-12 bg-background-3 rounded"></div>
          </div>
          <div className="flex flex-col space-y-4">
            <div className="flex items-center gap-2">
              <div className="h-4 w-24 bg-background-3 rounded"></div>
              <div className="h-4 w-28 bg-background-3 rounded"></div>
            </div>
            <div className="flex items-center gap-2">
              <div className="h-4 w-24 bg-background-3 rounded"></div>
              <div className="h-4 w-28 bg-background-3 rounded"></div>
            </div>
            <div className="flex items-center gap-2">
              <div className="h-4 w-24 bg-background-3 rounded"></div>
              <div className="h-4 w-28 bg-background-3 rounded"></div>
            </div>
            <div className="flex items-center gap-2">
              <div className="h-4 w-24 bg-background-3 rounded"></div>
              <div className="h-4 w-28 bg-background-3 rounded"></div>
            </div>
            <div className="flex items-center gap-2">
              <div className="h-4 w-24 bg-background-3 rounded"></div>
              <div className="h-4 w-28 bg-background-3 rounded"></div>
            </div>
          </div>
        </div>
        <div className="flex flex-col space-y-4 p-4 border border-background-3 rounded-md shadow-sm animate-pulse">
          <div className="flex items-center justify-between">
            <div className="h-5 w-32 bg-background-3 rounded"></div>
            <div className="h-5 w-24 bg-background-3 rounded"></div>
            <div className="h-5 w-12 bg-background-3 rounded"></div>
          </div>
          <div className="flex flex-col space-y-4">
            <div className="flex items-center gap-2">
              <div className="h-4 w-24 bg-background-3 rounded"></div>
              <div className="h-4 w-28 bg-background-3 rounded"></div>
            </div>
            <div className="flex items-center gap-2">
              <div className="h-4 w-24 bg-background-3 rounded"></div>
              <div className="h-4 w-28 bg-background-3 rounded"></div>
            </div>
            <div className="flex items-center gap-2">
              <div className="h-4 w-24 bg-background-3 rounded"></div>
              <div className="h-4 w-28 bg-background-3 rounded"></div>
            </div>
            <div className="flex items-center gap-2">
              <div className="h-4 w-24 bg-background-3 rounded"></div>
              <div className="h-4 w-28 bg-background-3 rounded"></div>
            </div>
            <div className="flex items-center gap-2">
              <div className="h-4 w-24 bg-background-3 rounded"></div>
              <div className="h-4 w-28 bg-background-3 rounded"></div>
            </div>
          </div>
        </div>
        <div className="flex flex-col space-y-4 p-4 border border-background-3 rounded-md shadow-sm animate-pulse">
          <div className="flex items-center justify-between">
            <div className="h-5 w-32 bg-background-3 rounded"></div>
            <div className="h-5 w-24 bg-background-3 rounded"></div>
            <div className="h-5 w-12 bg-background-3 rounded"></div>
          </div>
          <div className="flex flex-col space-y-4">
            <div className="flex items-center gap-2">
              <div className="h-4 w-24 bg-background-3 rounded"></div>
              <div className="h-4 w-28 bg-background-3 rounded"></div>
            </div>
            <div className="flex items-center gap-2">
              <div className="h-4 w-24 bg-background-3 rounded"></div>
              <div className="h-4 w-28 bg-background-3 rounded"></div>
            </div>
            <div className="flex items-center gap-2">
              <div className="h-4 w-24 bg-background-3 rounded"></div>
              <div className="h-4 w-28 bg-background-3 rounded"></div>
            </div>
            <div className="flex items-center gap-2">
              <div className="h-4 w-24 bg-background-3 rounded"></div>
              <div className="h-4 w-28 bg-background-3 rounded"></div>
            </div>
            <div className="flex items-center gap-2">
              <div className="h-4 w-24 bg-background-3 rounded"></div>
              <div className="h-4 w-28 bg-background-3 rounded"></div>
            </div>
          </div>
        </div>

      </div>
    )
  }

  else if (type === "CardList") {
    return (
      <>
        <div className="flex flex-col space-y-3 p-4 border border-background-3 rounded-md animate-pulse">
          <div className="flex justify-between items-center">
            <p className="h-3 w-28 bg-background-3 rounded"></p>
            <p className="h-3 w-16 bg-background-3 rounded"></p>
          </div>
          <div className="flex items-center space-x-4">
            <p className="h-3 w-12 bg-background-3 rounded"></p>
            <p className="h-3 w-12 bg-background-3 rounded"></p>
          </div>
          <div className="flex items-center space-x-4">
            <p className="h-3 w-16 bg-background-3 rounded"></p>
            <p className="h-3 w-32 bg-background-3 rounded"></p>
          </div>
        </div>
        <div className="flex flex-col space-y-3 p-4 mt-3 border border-background-3 rounded-md animate-pulse">
          <div className="flex justify-between items-center">
            <p className="h-3 w-28 bg-background-3 rounded"></p>
            <p className="h-3 w-16 bg-background-3 rounded"></p>
          </div>
          <div className="flex items-center space-x-4">
            <p className="h-3 w-12 bg-background-3 rounded"></p>
            <p className="h-3 w-12 bg-background-3 rounded"></p>
          </div>
          <div className="flex items-center space-x-4">
            <p className="h-3 w-16 bg-background-3 rounded"></p>
            <p className="h-3 w-32 bg-background-3 rounded"></p>
          </div>
        </div>
        <div className="flex flex-col space-y-3 p-4 mt-3 border border-background-3 rounded-md animate-pulse">
          <div className="flex justify-between items-center">
            <p className="h-3 w-28 bg-background-3 rounded"></p>
            <p className="h-3 w-16 bg-background-3 rounded"></p>
          </div>
          <div className="flex items-center space-x-4">
            <p className="h-3 w-12 bg-background-3 rounded"></p>
            <p className="h-3 w-12 bg-background-3 rounded"></p>
          </div>
          <div className="flex items-center space-x-4">
            <p className="h-3 w-16 bg-background-3 rounded"></p>
            <p className="h-3 w-32 bg-background-3 rounded"></p>
          </div>
        </div>
        <div className="flex flex-col space-y-3 p-4 mt-3 border border-background-3 rounded-md animate-pulse">
          <div className="flex justify-between items-center">
            <p className="h-3 w-28 bg-background-3 rounded"></p>
            <p className="h-3 w-16 bg-background-3 rounded"></p>
          </div>
          <div className="flex items-center space-x-4">
            <p className="h-3 w-12 bg-background-3 rounded"></p>
            <p className="h-3 w-12 bg-background-3 rounded"></p>
          </div>
          <div className="flex items-center space-x-4">
            <p className="h-3 w-16 bg-background-3 rounded"></p>
            <p className="h-3 w-32 bg-background-3 rounded"></p>
          </div>
        </div>
        <div className="flex flex-col space-y-3 p-4 mt-3 border border-background-3 rounded-md animate-pulse">
          <div className="flex justify-between items-center">
            <p className="h-3 w-28 bg-background-3 rounded"></p>
            <p className="h-3 w-16 bg-background-3 rounded"></p>
          </div>
          <div className="flex items-center space-x-4">
            <p className="h-3 w-12 bg-background-3 rounded"></p>
            <p className="h-3 w-12 bg-background-3 rounded"></p>
          </div>
          <div className="flex items-center space-x-4">
            <p className="h-3 w-16 bg-background-3 rounded"></p>
            <p className="h-3 w-32 bg-background-3 rounded"></p>
          </div>
        </div>
      </>
    );
  }

  else if (type === "popup") {
    return (
      <div className="grid grid-cols-1  px-8 md:grid-cols-2 md:h-[25rem] h-[40rem] p-4 divide-x-0 md:divide-x divide-border-1">
        <div className="pr-0 md:pr-4">
          <div className="flex items-center space-x-1 animate-pulse">
            <p className="w-7 h-7 bg-background-3 rounded-full border border-border-1"></p>
            <p className="h-7 w-40 bg-background-3 rounded"></p>
          </div>
          {/* Notes */}
          <div className="mt-4 animate-pulse">
            <p className="h-4 w-14 bg-background-3 rounded"></p>
            <p className="mt-3 h-3 w-20 bg-background-3 rounded"></p>
          </div>
          {/* Notes */}
          <div className="mt-4">
            <div className="relative">
              <div className="animate-pulse">
                <div className="md:w-[23rem] w-[16rem] h-28  bg-background-3h-24 rounded-t-md border border-border p-2">
                  <div className="h-full w-full bg-background-3 rounded-t-md flex items-center justify-center">
                    <p className="bg-background-3"></p>
                  </div>
                </div>
                <div className="md:w-[23rem]  w-[16rem] bottom-0 p-2 bg-transparent border border-border rounded-b-md flex justify-end space-x-0.5">
                  <div className="h-10 w-24 bg-background-3 rounded border border-border-1"></div>
                  <div className="h-10 w-24 bg-background-3 rounded border border-border-1"></div>
                </div>
              </div>
            </div>

            <div className="flex justify-between mt-2.5 animate-pulse">
              <div className="flex space-x-4 items-center">
                <p className="h-1.5 w-1.5 bg-background-3 rounded"></p>
                <p className="h-3 w-56 lg:w-80 bg-background-3 rounded"></p>
              </div>

            </div>
            <div className="flex justify-between mt-2.5 animate-pulse">
              <div className="flex space-x-4 items-center">
                <p className="h-1.5 w-1.5 bg-background-3 rounded"></p>
                <p className="h-3 w-40 lg:w-60 bg-background-3 rounded"></p>
              </div>

            </div>
          </div>

          {/* Questions */}
          <div className="mt-6">
            <p className="h-4 w-20 bg-background-3 rounded animate-pulse"></p>
            <div className="mt-3">
              <div className="flex justify-between animate-pulse">
                <div className="flex space-x-4 items-center">
                  <p className="h-1.5 w-1.5 bg-background-3 rounded"></p>
                  <p className="h-3 w-40 bg-background-3 rounded"></p>
                </div>

              </div>
              <div className="flex flex-col md:hidden mt-2.5 animate-pulse">
                <div className="flex space-x-4 items-center mb-4">
                  <p className="h-4 w-24 bg-background-3 rounded"></p> {/* Skeleton for "Details" title */}
                </div>

                <div className="flex flex-col space-y-2">
                  <div className="flex space-x-4 items-center">
                    <p className="h-3 w-16 bg-background-3 rounded"></p> {/* Skeleton for "Name" label */}
                    <p className="h-3 w-40 bg-background-3 rounded"></p> {/* Skeleton for "Ahahsh" */}
                  </div>

                  <div className="flex space-x-4 items-center">
                    <p className="h-3 w-16 bg-background-3 rounded"></p> {/* Skeleton for "Email" label */}
                    <p className="h-3 w-40 bg-background-3 rounded"></p> {/* Skeleton for "gaha@gmail.com" */}
                  </div>

                  <div className="flex space-x-4 items-center">
                    <p className="h-3 w-24 bg-background-3 rounded"></p> {/* Skeleton for "Subscription" label */}
                    <p className="h-3 w-28 bg-background-3 rounded"></p> {/* Skeleton for "N/A" */}
                  </div>

                  <div className="flex space-x-4 items-center">
                    <p className="h-3 w-16 bg-background-3 rounded"></p> {/* Skeleton for "Status" label */}
                    <p className="h-3 w-28 bg-background-3 rounded"></p> {/* Skeleton for "N/A" */}
                  </div>

                  <div className="flex space-x-4 items-center">
                    <p className="h-3 w-20 bg-background-3 rounded"></p> {/* Skeleton for "Trust Level" label */}
                    <p className="h-3 w-40 bg-background-3 rounded"></p> {/* Skeleton for "Low (Score 0.1)" */}
                  </div>

                  <div className="flex space-x-4 items-center">
                    <p className="h-3 w-20 bg-background-3 rounded"></p> {/* Skeleton for "Created on" label */}
                    <p className="h-3 w-32 bg-background-3 rounded"></p> {/* Skeleton for "3 April 2024" */}
                  </div>

                  <div className="flex space-x-4 items-center">
                    <p className="h-3 w-16 bg-background-3 rounded"></p> {/* Skeleton for "Source" label */}
                    <p className="h-3 w-28 bg-background-3 rounded"></p> {/* Skeleton for "N/A" */}
                  </div>

                  <div className="flex space-x-4 items-center mt-4">
                    <p className="h-3 w-36 bg-background-3 rounded"></p> {/* Skeleton for "Updated x days ago" */}
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>

        <div className="pl-0 md:pl-4 hidden md:block mt-10 md:mt-0">
          <div className="flex justify-between animate-pulse">
            <p className="h-4 w-14 bg-background-3 rounded"></p>
            <p className="h-8 w-24 bg-background-3 rounded"></p>
          </div>
          <div className="mt-6 flex flex-col space-y-5 animate-pulse">
            <div className="flex items-center">
              <p className="h-4 w-12 bg-background-3 rounded mr-16"></p>
              <p className="h-4 w-28 bg-background-3 rounded"></p>
            </div>
            <div className="flex items-center">
              <p className="h-4 w-12 bg-background-3 rounded mr-16"></p>
              <p className="h-4 w-36 bg-background-3 rounded"></p>
            </div>
            <div className="flex items-center">
              <p className="h-4 w-20 bg-background-3 rounded mr-8"></p>
              <p className="h-4 w-28 bg-background-3 rounded"></p>
            </div>
            <div className="flex items-center">
              <p className="h-4 w-12 bg-background-3 rounded mr-16"></p>
              <p className="h-4 w-28 bg-background-3 rounded"></p>
            </div>
            <div className="flex items-center">
              <p className="h-4 w-16 bg-background-3 rounded mr-12"></p>
              <p className="h-4 w-36 bg-background-3 rounded"></p>
            </div>
            <div className="flex items-center">
              <p className="h-4 w-16 bg-background-3 rounded mr-12"></p>
              <p className="h-4 w-40 bg-background-3 rounded"></p>
            </div>
            <div className="flex items-center">
              <p className="h-4 w-12 bg-background-3 rounded mr-16"></p>
              <p className="h-4 w-28 bg-background-3 rounded"></p>
            </div>
            <div className="flex items-center">
              <p className="h-4 w-16 bg-background-3 rounded mr-12"></p>
              <p className="h-4 w-20 bg-background-3 rounded"></p>
            </div>
            <div className="flex items-center">
              <p className="h-2.5 w-36 bg-background-3 rounded mr-12"></p>
            </div>
          </div>
        </div>

      </div>


    );
  } else if (type === "sendEmail") {
    return [36, 40, 28, 48, 24, 36, 40, 28, 48, 24].map((i, index) => (
      <div
        key={`${i}__send_email_skeleton__${index}`}
        className="border-b border-border-1"
      >
        <div className="my-4 flex items-center space-x-2 animate-pulse">
          <p className="w-4 h-4 border rounded"></p>
          <p className={`h-3.5 w-${i} bg-background-3 rounded w-`}></p>
        </div>
      </div>
    ));
  } else {
    // Lead Card Skeleton
    return <p>Hello World</p>;
  }
};

export default Skeleton;
