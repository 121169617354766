import React, { useState } from "react";
import useAuth from "../hooks/useAuth";
import { Link, useNavigate } from "react-router-dom";
import Button from "../components/Button";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { signIn, error, setError, setUser } = useAuth();

  // handle login here
  const handleLogin = (e) => {
    e.preventDefault();
    if (email === "" || password === "") {
      setError("You have to provide email & password to login!");
      return;
    }
    setLoading(true);
    signIn(email, password)
      .then((res) => {
        error && setError("");
        setUser(res.user);
        navigate("/");
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="flex justify-center items-center h-screen bg-background">
      <div>
        <Link to="/" className="flex items-center justify-center">
          <div className="text-3xl font-semibold">
            <img
              src="/images/logo-small.png"
              className="h-5 w-auto sm:h-6 lg:h-8"
              alt="Rabbito Logo"
            />
          </div>
          <span className="ml-2 md:ml-2 sm:text-2xl md:text-3xl text-logo font-spartan font-semibold">
            rabbito
          </span>
        </Link>

        <div className="flex flex-col items-center justify-center gap-y-2.5">
          <h2 className="font-semibold text-heading-1 text-lg sm:text-2xl">
            Login to your account
          </h2>
          <span className="text-description text-sm max-w-xs text-center">
            Welcome Sales Engineer, Please enter Credentials!
          </span>
        </div>

        <form
          className="flex flex-col gap-5 w-full max-w-sm md:max-w-md lg:max-w-sm mx-auto mt-5"
          onSubmit={handleLogin}
        >
          <div className="flex flex-col gap-2 text-label">
            <label htmlFor="email" className="block text-sm font-medium">
              Email
            </label>
            <input
              name="email"
              type="email"
              placeholder="Enter your email"
              className="appearance-none bg-background-input shadow-sm block w-full px-3 py-2 border border-border rounded-md placeholder-gray-400 sm:text-sm focus:z-10 focus:outline-none focus:border-primary focus:ring-primary"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="flex flex-col gap-2 text-label">
            <label htmlFor="password" className="block text-sm font-medium">
              Password
            </label>
            <input
              name="password"
              type="password"
              placeholder="••••••••"
              className="appearance-none bg-background-input shadow-sm block w-full px-3 py-2 border border-border rounded-md placeholder-gray-400 sm:text-sm focus:z-10 focus:outline-none focus:border-primary focus:ring-primary"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          {error && <span className="text-xs text-error-red">{error}</span>}

          <Button
            loading={loading}
            type="submit"
            className="flex justify-center"
          >
            {" "}
            Sign in
          </Button>
        </form>
      </div>
    </div>
  );
};

export default Login;
