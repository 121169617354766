export const boardColumns = [
  {
    id: "NEW",
    title: "Leads",
  },
  {
    id: "QUALIFIED",
    title: "Qualified",
  },
  {
    id: "ENGAGED",
    title: "Discovery",
  },
  {
    id: "SALE",
    title: "Hosted",
  },
  {
    id: "ABANDONED",
    title: "Abandoned",
  },

];

export const boardStatus = {
  NEW: "Leads",
  QUALIFIED: "Qualified",
  ENGAGED: "Discovery",
  SALE: "Hosted",
  ABANDONED: "Abandoned"
};
