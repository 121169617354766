import React, { useEffect, useState } from "react";
import SidebarLayout from "../layouts/SidebarLayout";
import DataTable from "./DataTable";
import Pagination from "./Pagination";
import axios from "axios";
import { useSearch } from "../context/SearchContext";
import Button from "./Button";
import CheckBox from "./CheckBox";
import CardList from "./CardList";
import Skeleton from "./Skeleton";

const Waitlist = () => {
  const [dataTable, setDataTable] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const { search } = useSearch();

  useEffect(() => {
    const fetchData = () => {
      setLoading(true);
      let apiUrl =
        `/crm/?source=rabbito&campaign=waitlist${search ? `&search=${search}` : ''}`;
      axios
        .get(apiUrl, {
          headers: {
            "x-api-key": process.env.REACT_APP_X_API_KEY_CRM
          }
        })
        .then(response => {
          setDataTable(response.data.data);
          console.log(response.data.data);
          setLoading(false);
        })
        .catch(error => {
          console.log(error);
          setLoading(false);
        });
    };

    fetchData();
  }, [search]);
  const handleSelectAllChange = (isChecked) => {
    const allEmails = isChecked ? dataTable.map((row) => row.email) : [];
    setSelectedRows(allEmails);
  };



  const handleRowSelect = (email) => {
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.includes(email)
        ? prevSelectedRows.filter((rowEmail) => rowEmail !== email)
        : [...prevSelectedRows, email]
    );
  };

  const columns = [
    {
      key: "checkbox",
      name: (
        <CheckBox
          className="px-0"
          name=""
          id="selectAll"
          checked={selectedRows.length === dataTable.length}
          onClick={() => handleSelectAllChange(selectedRows.length !== dataTable.length)}
        />
      ),
      render: (row) => (
        <CheckBox
          name={row.id}
          id={row.email}
          checked={selectedRows.includes(row.email)}
          onClick={() => handleRowSelect(row.email)}
        />
      ),
    },
    {
      key: "fullName",
      name: (
        <p h2 className=" font-semibold text-base leading-5 text-tableheader">
          Name
        </p>
      ),
      render: (row) => (
        <div className="flex items-center">
          <p className="text-base  text-center leading-5 text-tablebody">
            {row?.fullName}
          </p>
        </div>
      )
    },
    {
      key: "joiningDate",
      name: (
        <p h2 className="  font-semibold text-base leading-5 text-tableheader">
          Joining Date
        </p>
      ),
      render: (row) => (
        <div className="flex items-center">
          <p className="text-base leading-5 text-tablebody">
            {row?.joiningDate ? new Date(row.joiningDate).toLocaleDateString() : ''}
          </p>
        </div>
      )
    },

    {
      key: "email",
      name: (
        <p h2 className="  font-semibold text-base leading-5 text-tableheader">
          Email
        </p>
      ),
      render: (row) => (
        <div className="flex items-center">
          <p className="text-base leading-5 text-tablebody ">
            {row?.email}
          </p>
        </div>
      )
    },
    {
      key: "phone",
      name: (
        <p h2 className="  font-semibold text-base leading-5 text-tableheader">
          Phone
        </p>
      ),
      render: (row) => (
        <div className="flex items-center">
          <p className="text-base  leading-5 text-tablebody mx-0 ">
            {row?.phone}
          </p>
        </div>
      )
    },
    {
      key: "source",
      name: (
        <p h2 className=" font-semibold text-base leading-5 text-tableheader">
          Source
        </p>
      ),
      render: (row) => (
        <div className="flex items-center">
          <p className="text-base  leading-5 text-tablebody mx-0">
            {row?.source}
          </p>
        </div>
      )
    },
    {
      key: "status",
      name: (
        <p className=" font-semibold text-base leading-5 text-tableheader">
          Status
        </p>
      ),
      render: (row) => (
        <div className="flex items-center">
          <p className="text-base lowercase  leading-5 text-tablebody ">
            {row?.status}
          </p>
        </div>
      )
    }
  ]






  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
  const totalItems = dataTable.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const getCurrentPageItems = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    console.log('Start Index:', startIndex, 'End Index:', endIndex); // Debug log
    return dataTable.slice(startIndex, endIndex);
  };

  const handlePageChange = pageNumber => {
    setCurrentPage(pageNumber);
  };

  const getEmail = () => {
    if (selectedRows.length > 0) {
      console.log("Selected email:", selectedRows);
    } else {
      console.log("No row selected");
    }
  };

  return (
    <SidebarLayout>
      <div className="px-3 md:px-6 lg:px-8 py-2 md:py-4 lg:py-6 overflow-x-auto overflow-y-hidden">
        <div className="flex md:justify-between justify-end align-content-center ">
          <h2 className="text-3xl hidden md:block font-semibold  text-heading-1">
            Waitlist
          </h2>
          <Button kind="primary" onClick={getEmail} className={`${selectedRows.length === 0 ? 'hidden' : ""}`}>
            On Board
          </Button>
        </div>

        <div className=" md:hidden block">
          <div className=" flex items-center justify-between mb-3">
            <h2 className="md:text-3xl text-lg font-semibold  text-heading-1">
              Waitlist
            </h2>
            <CheckBox
              className="px-0"
              name=""
              id="selectAll"
              checked={selectedRows.length === dataTable.length}
              onClick={() => handleSelectAllChange(selectedRows.length !== dataTable.length)}
            />
          </div>

          <div className="mt-1 md:hidden block">
            {loading ? <Skeleton type={"waitlist"} /> : (
              <CardList>
                {dataTable.map((row) => (
                  <div key={row.email} className="border border-border rounded-lg p-4 m-3 shadow-sm">
                    <div className="flex items-center justify-between pb-2">
                      <p className="text-tablebody">
                        <span className='text-tableheader font-semibold'>ID:</span> {row.id}
                      </p>
                      <p className="text-tablebody">
                        <span className='text-tableheader font-semibold'>Name:</span> {row.fullName}
                      </p>
                      <CheckBox
                        name={row.id}
                        id={row.email}
                        checked={selectedRows.includes(row.email)}
                        onClick={() => handleRowSelect(row.email)}
                      />
                    </div>
                    <div className="flex flex-col gap-3">
                      <p className="text-tablebody">
                        <span className='text-tableheader font-semibold'>Joining Date:</span> {row.joiningDate ? new Date(row.joiningDate).toLocaleDateString() : ''}
                      </p>
                      <p className="text-tablebody">
                        <span className='text-tableheader font-semibold'>Email:</span> {row.email}
                      </p>
                      <p className="text-tablebody">
                        <span className='text-tableheader font-semibold'>Phone:</span> {row.phone}
                      </p>
                      <p className="text-tablebody">
                        <span className='text-tableheader font-semibold'>Source:</span> {row.source}
                      </p>
                      <p className="text-tablebody lowercase">
                        <span className='text-tableheader font-semibold'>Status:</span> {row.status}
                      </p>
                    </div>
                  </div>
                ))}
              </CardList>

            )}

          </div>
        </div>

        <div className="mt-5 hidden md:block">
          <DataTable
            columns={columns}
            loading={loading}
            data={getCurrentPageItems()}
          />

          <div className="mt-6 hidden md:block">
            {totalPages > 1 && (
              <div className="mt-6">
                <Pagination
                  totalPages={totalPages}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  onPageChange={handlePageChange}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </SidebarLayout>
  );
};

export default Waitlist;
