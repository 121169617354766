import React from "react";
import c from "classnames";
import { useNavigate } from "react-router-dom";

const buttonClassesMap = {
  common:
    "inline-flex relative py-2 px-4 border shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2",
  primary:
    "border-transparent rounded-md text-white bg-primary hover:bg-primary-hover  focus:outline-none disabled:cursor-not-allowed disabled:bg-primary-disabled  disabled:text-secondary-disabled-txt",
  danger:
    "border-transparent rounded-md text-red-700 bg-red-100 hover:bg-red-200 focus:ring-red-500",
  secondary:
    "border-border hover:border-secondary-border-hover focus:border-secondary-border-focus rounded-md text-secondary-txt bg-secondary hover:bg-secondary-hover focus:bg-secondary-focus disabled:text-secondary-disabled-txt focus:outline-none",
  transparent:
    " rounded text-primary border-border  bg-transparent w-7 h-9 hover:bg-secondary-hover focus:outline-none focus:ring-none focus:border-none ",
  Outline:
    " rounded border border-border rounded-md focus:outline-none font-bold bg-transparent ",

};

export default function Button({
  className,
  kind = "primary",
  loading,
  children,
  onClick,
  href,
  ...props
}) {
  const navigate = useNavigate();
  let onButtonClick = onClick;
  if (href) {
    onButtonClick = (e) => {
      if (typeof href !== "string") navigate(href.toString());
      else navigate(href);
      if (onClick) onClick(e);
    };
  }

  let loaderClass = "border-gray-100  border-t-gray-600";
  if (kind === "primary") {
    loaderClass = "border-gray-100 border-t-primary";
  } else if (kind === "secondary") {
    loaderClass = "border-border-1 border-t-secondary-txt";
  }

  return (
    // eslint-disable-next-line react/button-has-type
    <button
      disabled={loading || props.disabled}
      className={c(buttonClassesMap[kind], buttonClassesMap.common, className)}
      onClick={onButtonClick}
      {...props}
    >
      {loading && (
        <div className="left-0 absolute w-full flex justify-center">
          <div
            className={c(
              `border-2 w-5 h-5 rounded-full animate-spin`,
              loaderClass
            )}
          />
        </div>
      )}
      <span
        className={
          loading
            ? "opacity-0 inline-flex items-center"
            : "inline-flex items-center"
        }
      >
        {children}
      </span>
    </button>
  );
}
