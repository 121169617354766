import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { FiBell, FiX } from "react-icons/fi";

const Notifications = () => {


  return (
    <Menu as="div" className="mr-2 relative ">
      <Menu.Button
        className="max-w-xs flex items-center text-sm rounded-full bg-transparent border border-border md:p-2.5 p-2 focus:outline-none"
      >
        <FiBell className="md:w-6 md:h-6 h-4 w-4 text-logo2 relative" />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right fixed top-0 md:top-auto md:absolute right-0 z-10 mt-0 md:mt-2 w-screen md:w-96 h-96 md:h-80 overflow-y-auto rounded-md shadow-lg py-1 bg-background-4 ring-1 ring-black ring-opacity-5 focus:outline-none dark:border dark:border-border-1">
          <div className="px-4 py-2 flex md:hidden justify-between items-center">
            <h2 className="text-xl font-semibold leading-7 text-heading-2">
            </h2>
            <Menu.Button>
              <FiX className="font-bold w-6 h-6 text-heading-2" />
            </Menu.Button>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default Notifications;
