import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { IoIosArrowDown } from 'react-icons/io';

const Dropdown = ({ selectedFilter, options, onFilterChange }) => {
    return (
        <Menu as="div" className="relative">
            <Menu.Button className="inline-flex items-center py-2  px-3 min-w-[7rem] justify-between z-10 rounded opacity-90 hover:opacity-100 font-medium border border-border">
                <p className=" text-xs truncate  ">{selectedFilter}</p>
                <IoIosArrowDown className="h-5 w-5 text-heading-1" aria-hidden="true" />
            </Menu.Button>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute right-0 mt-2 z-50 min-w-[7rem] divide-y divide-border-border-1 rounded-md bg-background-4 shadow-lg focus:outline-none border border-border-1">
                    <div className="px-1 py-2">
                        {options.map((option, index) => (
                            <Menu.Item key={index}>
                                <button
                                    onClick={() => onFilterChange(option)}
                                    className="hover:bg-background-3 hover:text-white text-black mt-1 text-md text-label group flex gap-1 w-full items-center rounded-md p-2 text-sm"
                                >
                                    <span>{option}</span>
                                </button>
                            </Menu.Item>
                        ))}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    );
};

export default Dropdown;
