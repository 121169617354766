import React, { Fragment, useState } from "react";
import MarketingLayout from "../../layouts/MarketingLayout";
import { Listbox, Transition } from "@headlessui/react";
import { RiArrowDropDownLine } from "react-icons/ri";
import CheckBox from "../../components/CheckBox";
import Button from "../../components/Button";
import { targetAudience } from "../../constants/targetAudience";
import axios from "axios";
import toast from "react-hot-toast";
import useSWR from "swr";
import Skeleton from "../../components/Skeleton";

const Email = () => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [selectedTargetAudience, setSelectedTargetAudience] = useState({
    name: "",
    segmentId: "",
  });
  const [lastSentMailInfo, setLastSentMailInfo] = useState({
    templateId: "",
    segmentId: "",
  });

  const { data: { data: emailTemplates } = {}, error: templatesError } = useSWR(
    `crm/templates/rabbito`
  );

  const sendEmails = () => {
    if (!selectedTargetAudience.segmentId)
      return setError("Please select your target audience!");

    const { id, name, templateData } = selectedTemplate || {};
    if (!id) return setError("Select a template from the list to send email!"); // Will never encounter

    error && setError("");
    setLoading(true);

    axios
      .post("/notification/singlesends", {
        dynamicTemplateId: id,
        segmentLists: [selectedTargetAudience.segmentId],
        dynamicTemplateData: {
          ...(templateData || {}),
        },
        name: "Rabbito",
        subject: name,
      })
      .then(async (res) => {
        if (res.data?.code === 200) {
          setLastSentMailInfo({
            templateId: id,
            segmentId: selectedTargetAudience.segmentId,
          });
          toast.success(
            `${name} mail has been Successfully sent to the targeted ${selectedTargetAudience.name} audinece`
          );
        }
      })
      .catch((err) => {
        const error =
          err?.response?.data?.message || err?.response?.data?.error;
        if (error) setError(error);
        else setError("Something went wrong!");
      })
      .finally(() => setLoading(false));
  };

  return (
    <MarketingLayout
      sendEmails={sendEmails}
      loading={loading}
      selectedTemplate={selectedTemplate}
      setSelectedTemplate={setSelectedTemplate}
      selectedTargetAudience={selectedTargetAudience}
      setSelectedTargetAudience={setSelectedTargetAudience}
      lastSentMailInfo={lastSentMailInfo}
      setLastSentMailInfo={setLastSentMailInfo}
      error={error}
      setError={setError}>

      <div className="mt-8 no-scrollbar overflow-y-auto">
        {!emailTemplates && !templatesError ? (
          <Skeleton type={"sendEmail"} />
        ) : emailTemplates?.length ? (
          emailTemplates.map((template) => (
            <CheckBox
              key={template.id}
              name={template.name}
              id={template.id}
              hasBottomBorder={true}
              onClick={() => {
                setSelectedTemplate((prevSelected) =>
                  prevSelected.id === template.id
                    ? {}
                    : {
                      ...template,
                      templateData: {
                        ...template.templateData,
                      },
                    }
                );
              }}
              checked={selectedTemplate.id === template.id}
            />
          ))
        ) : (
          ""
        )}
      </div>
      {(error || templatesError) && (
        <span className="text-sm text-error-red mt-4 inline-block text-right w-full">
          {error ||
            templatesError?.response?.data?.message ||
            templatesError?.response?.data?.error}
        </span>
      )}


    </MarketingLayout>
  );
};

export default Email;
