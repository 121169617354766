import { Dialog, Transition } from "@headlessui/react";
import {
  AiOutlineExclamation,
  AiOutlineClose,
  AiOutlineCheckCircle,
} from "react-icons/ai";

import React, {
  createContext,
  Fragment,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";
import Button from "./Button";

const ConfirmationServiceContext = createContext(Promise.reject);

export const useConfirmation = () => useContext(ConfirmationServiceContext);

export function ConfirmationServiceProvider({ children }) {
  const [props, setProps] = useState({});
  const [show, setShow] = useState(false);
  const [apiError, setApiError] = useState("");
  const [loading, setLoading] = useState(false);

  const awaitingPromiseRef = useRef();
  const cancelButtonRef = useRef(null);
  var description = props.description;
  if (typeof description != "string") {
    description = "";
  }
  const openConfirmation = useCallback((options) => {
    setProps(options);
    setShow(true);
    return new Promise((resolve) => {
      awaitingPromiseRef.current = { resolve };
    });
  }, []);

  const handleCancel = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve(false);
    }
    setShow(false);
  };

  const handleConfirm = () => {
    if (awaitingPromiseRef.current) {
      if (props && props.callback) {
        const r = props.callback();
        if (Promise.resolve(r) === r) {
          setLoading(true);
          r.then(() => {
            setProps({});
            awaitingPromiseRef.current?.resolve(true);
          })
            .catch((e) => setApiError(e))
            .finally(() => {
              setLoading(false);
              setShow(false);
            });
        }
      } else {
        awaitingPromiseRef.current.resolve(true);
        setShow(false);
      }
    }
  };

  return (
    <>
      <ConfirmationServiceContext.Provider value={openConfirmation}>
        {children}
      </ConfirmationServiceContext.Provider>

      <Transition.Root show={show} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-40 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={handleCancel}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-background-4 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 relative">
                {props?.displayClose && (
                  <button
                    onClick={handleCancel}
                    className="absolute top-2 right-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary rounded"
                  >
                    <AiOutlineClose className="text-gray-500 cursor-pointer w-5 h-5" />
                  </button>
                )}

                {apiError && (
                  <span className="text-xs text-error-red block mb-2 text-right">
                    {apiError}
                  </span>
                )}
                <div className="sm:flex sm:items-start">
                  {props.danger && (
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <AiOutlineExclamation
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                  {!props.danger && (
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-indigo-100 sm:mx-0 sm:h-10 sm:w-10">
                      <AiOutlineCheckCircle
                        className="h-7 w-7 text-indigo-600"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-heading-2"
                    >
                      {props && props.title}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-description-1">
                        {props && description}
                      </p>
                    </div>
                  </div>
                </div>
                {!props?.displayClose && (
                  <div className="mt-5 mt-4 sm:ml-36 flex flex-row-reverse items-center space-x-4 space-x-reverse">
                    <div className="h-full w-6/12 sm:w-full flex justify-center items-center grow">
                      <Button
                        kind={props.danger ? "danger" : "primary"}
                        onClick={handleConfirm}
                        loading={loading}
                        type="button"
                        className="w-full justify-center items-center "
                      >
                        {props?.actionLabel}
                      </Button>
                    </div>
                    <div className="h-full w-6/12 sm:w-full flex justify-center items-center">
                      <button
                        type="button"
                        disabled={loading}
                        className="w-full px-2 grow py-1.5 sm:py-2 inline-flex items-center justify-center rounded-md border border-gray-300 shadow-sm bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                        onClick={handleCancel}
                        ref={cancelButtonRef}
                      >
                        {props?.cancelLabel || "Cancel"}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
