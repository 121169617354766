import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import Button from "../Button";
import { FiX } from "react-icons/fi";
import axios from "axios";

const AddNewLead = ({ isOpen, close, leadsMutate, status, source }) => {
  const [formVal, setFormVal] = useState({});
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const handleOnChange = (e) => {
    const { name, value } = e.target || {};
    setFormVal((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    error && setError("");
    setLoading(true);

    const { name, email, source, phone } = formVal || {};
    await axios
      .post(
        "/crm",
        {
          email,
          name,
          source: source || "rabbito",
          phone,
          status: status ? status : "",
        },
        { headers: { "x-api-key": process.env.REACT_APP_X_API_KEY_CRM || "" } }
      )
      .then(async () => {
        leadsMutate();
        close();
      })
      .catch((err) => {
        setError(err?.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={close}
      >
        <div className="relative min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-sm p-6 overflow-visible text-left align-middle transition-all transform bg-background-2  shadow-[0_1px_10px_3px_rgba(111,84,198,0.7)] rounded-[20px]">
              <Dialog.Title
                as="h3"
                className="text-lg font-semibold leading-7 text-heading-1 "
              >
                Add Lead
              </Dialog.Title>
              <button onClick={close}>
                <FiX className="h-5 w-5 text-heading-1  rounded-full  absolute top-7 right-7" />
              </button>

              <form
                className="flex flex-col gap-3 w-full max-w-sm md:max-w-md lg:max-w-sm mx-auto "
                onSubmit={handleSubmit}
              >
                <div className="flex flex-col gap-2 text-label">
                  <label htmlFor="name" className="block text-sm font-medium">
                    Lead Name
                  </label>
                  <input
                    name="name"
                    type="text"
                    placeholder="Enter lead full name"
                    className="appearance-none bg-background-input  block w-full px-3 py-2 border border-border rounded-md placeholder-gray-400 sm:text-sm focus:z-10 focus:outline-none focus:border-primary focus:ring-primary"
                    onChange={handleOnChange}
                  />
                </div>
                <div className="flex flex-col gap-2 text-label">
                  <label htmlFor="email" className="block text-sm font-medium">
                    Email
                  </label>
                  <input
                    required
                    name="email"
                    type="email"
                    placeholder="Enter lead email"
                    className="appearance-none bg-background-input  block w-full px-3 py-2 border border-border rounded-md placeholder-gray-400 sm:text-sm focus:z-10 focus:outline-none focus:border-primary focus:ring-primary"
                    onChange={handleOnChange}
                  />
                </div>
                <div className="flex flex-col gap-2 text-label">
                  <label htmlFor="source" className="block text-sm font-medium">
                    Lead Source
                  </label>
                  <input
                    name="leadSource"
                    type="text"
                    placeholder="Enter lead source"
                    className="appearance-none bg-background-input  block w-full px-3 py-2 border border-border rounded-md placeholder-gray-400 sm:text-sm focus:z-10 focus:outline-none focus:border-primary focus:ring-primary"
                    onChange={handleOnChange}
                  />
                </div>
                <div className="flex flex-col gap-2 text-label">
                  <label htmlFor="source" className="block text-sm font-medium">
                    Brand Source
                  </label>
                  <input
                    name="source"
                    type="text"
                    value={source}
                    disabled
                    className="appearance-none bg-background-input text-secondary-disabled-txt block w-full px-3 py-2 border border-border rounded-md placeholder-gray-400 sm:text-sm focus:z-10 focus:outline-none focus:border-primary focus:ring-primary"
                  />
                </div>
                <div className="flex flex-col gap-2 text-label">
                  <label htmlFor="phone" className="block text-sm font-medium">
                    Phone
                  </label>
                  <input
                    name="phone"
                    type="tel"
                    placeholder="Enter lead phone number"
                    className="appearance-none bg-background-input  block w-full px-3 py-2 border border-border rounded-md placeholder-gray-400 sm:text-sm focus:z-10 focus:outline-none focus:border-primary focus:ring-primary"
                    onChange={handleOnChange}
                  />
                </div>
                {error && (
                  <span className="text-xs text-error-red">{error}</span>
                )}

                <Button
                  loading={loading}
                  disabled={!formVal?.name || !formVal?.email || !formVal?.phone}
                  type="submit"
                  className="flex justify-center mt-4"
                >
                  {" "}
                  Add
                </Button>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AddNewLead;
