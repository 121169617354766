import { LineChart, ResponsiveContainer, Line, XAxis, Tooltip, YAxis, CartesianGrid } from "recharts";
import { IoIosArrowDown, IoIosSad } from "react-icons/io";
import { Menu, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import DropDown from '../DropDown'

const data = [
  { "name": "Jan", "Facebook": 3345, "Instagram": 3120, "LinkedIn": 1820, "X": 1580 },
  { "name": "Feb", "Facebook": 2150, "Instagram": 3280, "LinkedIn": 1960, "X": 1625 },
  { "name": "Mar", "Facebook": 4410, "Instagram": 3010, "LinkedIn": 2055, "X": 1730 },
  { "name": "Apr", "Facebook": 1985, "Instagram": 1500, "LinkedIn": 2150, "X": 3820 },
  { "name": "May", "Facebook": 2220, "Instagram": 3650, "LinkedIn": 2265, "X": 1910 },
  { "name": "Jun", "Facebook": 6580, "Instagram": 3750, "LinkedIn": 2375, "X": 2050 },
  { "name": "Jul", "Facebook": 2065, "Instagram": 1830, "LinkedIn": 5450, "X": 5180 },
  { "name": "Aug", "Facebook": 2480, "Instagram": 1950, "LinkedIn": 2510, "X": 2250 },
  { "name": "Sep", "Facebook": 1375, "Instagram": 4130, "LinkedIn": 2625, "X": 2335 },
  { "name": "Oct", "Facebook": 2230, "Instagram": 4070, "LinkedIn": 2740, "X": 2405 },
  { "name": "Nov", "Facebook": 2310, "Instagram": 4290, "LinkedIn": 3860, "X": 2500 },
  { "name": "Dec", "Facebook": 1470, "Instagram": 6390, "LinkedIn": 2930, "X": 2630 }
]


export default function DatalineChart() {
  const [selectedFilter, setSelectedFilter] = useState("This Month");
  const options = ['All', 'This Week', 'Last Week', 'This Month', 'Last Month', 'This Year', 'Last Year'];
  const handleFilterChange = (filter) => {
    setSelectedFilter(filter)
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-yellow-400 p-4 rounded-lg relative shadow-md text-black w-full max-w-xs sm:max-w-sm md:max-w-md">

          <p className="text-lg font-bold mb-2">{label}</p>
          {payload.map((entry, index) => (
            <div key={`item-${index}`} className="flex justify-between py-1">
              <span className="font-semibold">{entry.name}:</span>
              <span className="ml-2">{entry.value}</span>
            </div>
          ))}
        </div>

      );
    }

    return null;
  };

  return (
    <div className="flex-col flex md:flex md:flex-row justify-between gap-5 items-center">
      <div className=" border border-border rounded-lg p-6 mt-4  md:w-[80%]">
        <div className="flex justify-between items-center mb-3">
          <p className="text-xl font-semibold text-heading-1">Recent Leads</p>
          <div className=" w-52 text-right">
            <DropDown
              selectedFilter={selectedFilter}
              options={options}
              onFilterChange={handleFilterChange}
            />
          </div>
        </div>
        <ResponsiveContainer width="100%" height={323}>
          <LineChart data={data}>
            <Tooltip content={<CustomTooltip />} />
            <Line type="monotone" dataKey="Facebook" stroke="#8884d8" dot={false} />
            <Line type="monotone" dataKey="Instagram" stroke="#82ca9d" dot={false} />
            <Line type="monotone" dataKey="LinkedIn" stroke="#ffc658" dot={false} />
            <Line type="monotone" dataKey="X" stroke="#ff7300" dot={false} />
            <XAxis dataKey="name" />
            <YAxis />
            <CartesianGrid strokeDasharray="3 3" horizontal={true} vertical={false} />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <div className="border border-border rounded-lg h-64 md:h-[423px]  p-6 mt-4 w-[100%] md:w-[19%]">
        <h2 className="md:px-2 px-0 text-start text-lg mb-4 text-heading-1 font-semibold">Lead Source</h2>
        <div className=" p-4 text-secondary-txt-1 w-full hidden md:block ">
          {['Google', 'YouTube', 'Instagram', 'Tik Tok', 'Facebook', 'Twitter'].map((source) => (
            <div key={source} className="flex items-center py-4 justify-between ">
              <span className="text-sm">{source}</span>
              <div className="flex items-center gap-2 ">
                <div className="w-5 h-1 bg-gray-600 rounded"></div>
                <div className="w-5 h-1 bg-gray-300 rounded"></div>
                <div className="w-5 h-1 bg-gray-100 rounded"></div>
              </div>
            </div>
          ))}
        </div>
        <div className="  text-secondary-txt-1  w-full md:hidden block">
          <div className="flex justify-between items-center gap-3">
            <div>
              {['Google', 'YouTube', 'Instagram'].map((source) => (
                <div key={source} className="flex items-center gap-3 py-4 justify-between ">
                  <span className="text-sm">{source}</span>
                  <div className="flex items-center gap-2 ">
                    <div className="w-5 h-1 bg-gray-600 rounded"></div>
                    <div className="w-5 h-1 bg-gray-300 rounded"></div>
                  </div>
                </div>
              ))}
            </div>
            <div>
              {['TikTok', 'Facebook', 'X'].map((source) => (
                <div key={source} className="flex items-center gap-3 px-2 py-4 justify-between ">
                  <span className="text-sm">{source}</span>
                  <div className="flex items-center gap-2 ">
                    <div className="w-5 h-1 bg-gray-600 rounded"></div>
                    <div className="w-5 h-1 bg-gray-300 rounded"></div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
// 'Tik Tok', 'Facebook', 'Twitter'