import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { FiX } from "react-icons/fi";
import useSWR from "swr";
import { boardColumns } from "../../constants/board";
import axios from "axios";
import { toast } from "react-hot-toast";
import Loading from "../Loading";
import useAuth from "../../hooks/useAuth";
import Button from "../Button"; // Ensure this import matches your Button component path

const Moveto = ({ isOpen, close, email, source: leadSource, leadsMutate }) => {
    const { data: leadData, mutate: leadMutate } = useSWR(email && `/crm/${email}?source=${leadSource}`);
    const [selectedStatus, setSelectedStatus] = useState("");
    const [loading, setLoading] = useState(false);

    // Memoize lead data to avoid unnecessary updates
    const memoizedData = useMemo(() => leadData?.[email] ?? null, [email, leadData]);

    useEffect(() => {
        if (isOpen && memoizedData) {
            setSelectedStatus(memoizedData.status);
        }
    }, [isOpen, memoizedData]);

    const updateStatus = (updatedStatus = "") => {
        if (!updatedStatus) return;

        const body = { email, source: leadSource, status: updatedStatus };

        setLoading(true);

        axios
            .put("/crm", body, {
                headers: { "x-api-key": process.env.REACT_APP_X_API_KEY_CRM || "" },
            })
            .then(async () => {
                leadsMutate();
                await leadMutate();
                close();
            })
            .catch((err) => {
                const error = err?.response?.data?.message || "Something went wrong!";
                toast.error(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={close}>
                <div className="relative min-h-screen px-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <span className="inline-block h-screen align-middle" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className="inline-block w-full max-w-sm p-6 overflow-visible text-left align-middle transition-all transform bg-background-2 shadow-[0_1px_10px_3px_rgba(111,84,198,0.7)] rounded-3xl">
                            <Dialog.Title as="h3" className="text-lg font-semibold leading-7 text-heading-1">
                                Move to
                            </Dialog.Title>
                            <button onClick={close}>
                                <FiX className="h-5 w-5 text-heading-1 rounded-full absolute top-7 right-7" />
                            </button>
                            <div className="mt-4">

                                <div className="space-y-2 border-t-2 border-border py-2">
                                    {boardColumns.map((col) => (
                                        <div className="flex justify-between items-center  border-b-2 border-border p-2 cursor-pointer" onClick={() => setSelectedStatus(col.id)}>
                                            <span className="text-heading-1">{col.title}</span>
                                            <div className="relative">
                                                <input
                                                    type="radio"
                                                    name="status"
                                                    value={col.id}
                                                    checked={selectedStatus === col.id}
                                                    readOnly
                                                    className="relative appearance-none w-5 h-5 border-2 border-border rounded-full cursor-pointer"
                                                    style={{
                                                        WebkitAppearance: 'none',
                                                        MozAppearance: 'none'
                                                    }}
                                                />
                                                <div
                                                    className={`absolute top-[11px] left-1/2 w-3 h-3 rounded-full bg-primary transform -translate-x-1/2 -translate-y-1/2 ${selectedStatus === col.id ? 'opacity-100' : 'opacity-0'}`}
                                                />
                                            </div>
                                        </div>

                                    ))}
                                </div>
                                <Button kind="primary" className="w-full mt-4 flex justify-center items-center" onClick={() => updateStatus(selectedStatus)}>
                                    Move

                                    {loading && <Loading width="4" height="4" />}

                                </Button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    );
};

export default Moveto;
