import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import {
  DndContext,
  DragOverlay,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { SortableContext, arrayMove } from "@dnd-kit/sortable";
import { createPortal } from "react-dom";
import SidebarLayout from "../layouts/SidebarLayout";
import { boardColumns } from "../constants/board";
import { BsSliders } from "react-icons/bs";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import LeadCard from "../components/board/LeadCard";
import AddNewLead from "../components/board/AddLead";
import useSWR from "swr";
import axios from "axios";
import { toast } from "react-hot-toast";
import Skeleton from "../components/Skeleton";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import io from "socket.io-client";
import { apiGateway } from "../config";
import { useSearch } from "../context/SearchContext";
import { FiDownload, FiUpload } from "react-icons/fi";
import "../styles/swiper.css";
import ListBox from '../components/ListBox.js'
import { Menu, Transition } from "@headlessui/react";
import DropDown from '../components/DropDown.js';
import { FaPlus, FaSort } from "react-icons/fa6";
import { IoSearchOutline } from "react-icons/io5";
import { debounce } from "../utils/common.js";
import Papa from 'papaparse';
import Button from "../components/Button.js";

function Sales() {

  const [filters, setFilters] = useState(() => {
    const storedFilters =
      typeof window !== "undefined" && localStorage.getItem("filters");
    return storedFilters
      ? JSON.parse(storedFilters)
      : {
        status: "",
        source: "rabbito",
        subscription: "",
      };
  });
  const { search, setSearch } = useSearch();
  const debouncedSetSearch = debounce(setSearch, 1000);
  const {
    data: { data: allLeads } = {},
    error: leadsError,
    mutate: leadsMutate,
  } = useSWR(
    `crm?source=${filters.source}${search ? `&search=${search}` : ""}${filters.status ? `&status=${filters.status}` : ""
    }${filters.subscription ? `&subscription=${filters.subscription}` : ""}`
  );

  const [leads, setLeads] = useState([]);
  useEffect(() => {
    setLeads(allLeads ?? []);
    if (leadsError) {
      toast.error(leadsError?.response?.data?.message || leadsError?.message);
    }
  }, [allLeads, leadsError]);

  const [activeLead, setActiveLead] = useState(null);
  const [loadingLeads, setLoadingLeads] = useState(new Set());
  const [device, setDevice] = useState({ mobile: false, tab: false });
  const [showNotePopup, setShowNotePopup] = useState(false);
  const fileInputRef = useRef(null);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    fileInputRef.current.value = '';

    if (!file) {
      return;
    }

    if (!file.name.endsWith('.csv')) {
      toast.error('Please upload a CSV file.');
      return;
    }

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: async (result) => {
        const leadsData = result.data.map((row) => ({
          name: row.name || '',
          email: row.Email || '',
          phone: row.Phone || '',
          status: row.Status || 'NEW',
          source: row.Source || 'rabbito'
        }));

        console.log('Parsed leads data:', leadsData);

        for (let lead of leadsData) {
          try {
            const response = await axios.post(
              '/crm',
              { ...lead },
              { headers: { 'x-api-key': process.env.REACT_APP_X_API_KEY_CRM || '' } }
            );
            if (response.status === 200) {
              toast.success(`Lead ${lead.email} Imported successfully.`);
              try {
                const verifyResponse = await axios.post(
                  '/crm/verify',
                  { email: lead.email, source: lead.source },
                  { headers: { 'x-api-key': process.env.REACT_APP_X_API_KEY_CRM || '' } }
                );
                if (verifyResponse.status === 200) {
                  toast.success(`Lead ${lead.email} verified successfully.`);
                } else {
                  toast.warn(`Lead ${lead.email} imported, but email verification failed: ${verifyResponse.data.message}`);
                }
              } catch (verifyError) {
                toast.error(`Error verifying email for lead ${lead.email}: ${verifyError.response ? verifyError.response.data.message : verifyError.message}`);
              }
            } else {
              toast.error(`Error importing lead ${lead.email}.`);
            }
          } catch (error) {
            toast.error(`Error importing lead ${lead.email}.`);
          }
        }
        leadsMutate();
      },
      error: (error) => {
        console.error('Error parsing CSV:', error);
        toast.error('Error parsing CSV file. Please check the file format.');
      },
    });
  };



  useEffect(() => {
    if (typeof window !== "undefined")
      localStorage.setItem("filters", JSON.stringify(filters));
  }, [filters]);

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth <= 720;
      const isTab = window.innerWidth >= 720 && window.innerWidth <= 820;
      setDevice({ mobile: isMobile, tab: isTab });
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    })
  );

  function onDragOver(event) {
    const { active, over } = event;
    if (!over) return;

    const activeId = active.id;
    const overId = over.id;

    if (activeId === overId) return;

    const isActiveALead = active.data.current?.type === "Lead";
    const isOverLead = over.data.current?.type === "Lead";

    if (!isActiveALead) return;

    if (isActiveALead && isOverLead) {
      setLeads((leads) => {
        const oldLeads = [...leads];
        const activeIndex = leads.findIndex((l) => l.email === activeId);
        const overIndex = leads.findIndex((l) => l.email === overId);

        if (leads[activeIndex].status !== leads[overIndex].status) {
          const previousStatus = oldLeads[activeIndex].status;

          leads[activeIndex].status = leads[overIndex].status;

          if (previousStatus !== leads[activeIndex].status) {
            updateStatus(leads[activeIndex]);
          }
          return arrayMove(leads, activeIndex, overIndex - 1);
        }
        return arrayMove(leads, activeIndex, overIndex);
      });
    }

    const isOverAColumn = over.data.current?.type === "Column";
    if (isActiveALead && isOverAColumn) {
      setLeads((leads) => {
        const oldLeads = [...leads];
        const activeIndex = leads.findIndex((l) => l.email === activeId);

        const previousStatus = oldLeads[activeIndex].status;

        leads[activeIndex].status = overId;

        if (previousStatus !== leads[activeIndex].status) {
          updateStatus(leads[activeIndex]);
        }
        return arrayMove(leads, activeIndex, activeIndex);
      });
    }
  }

  function onDragEnd(event) {
    setActiveLead(null);
    const { active, over } = event;

    if (!over) return;

    const activeId = active.id;
    const overId = over.id;


    if (activeId === overId) return;

    const isActiveALead = active.data.current?.type === "Lead";
    const isOverAColumn = over.data.current?.type === "Column";

    if (isActiveALead && isOverAColumn) {
      const activeLeadIndex = leads.findIndex((lead) => lead.email === activeId);

      if (activeLeadIndex !== -1) {
        const activeLead = leads[activeLeadIndex];

        if (activeLead.status !== overId) {

          setActiveLead(activeLead);
          setShowNotePopup(true);
        }
      } else {
        console.log("Active lead not found in leads array.");
      }
    } else {
      console.log("Drag operation not valid.");
    }
  }
  const downloadCSV = () => {
    const csvData = allLeads.map(lead => ({
      'Name': lead.fullName,
      'Email': lead.email,
      'Phone': lead.phone,
      'Status': lead.status,
      'Source': lead.source

    }));
    const csvString = Papa.unparse(csvData);
    const downloadLink = document.createElement('a');
    downloadLink.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvString);
    downloadLink.target = '_blank';
    downloadLink.download = `all_leads.csv`; // Download filename
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  const isWithinLastMonth = (dateString) => {
    const today = new Date();
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(today.getMonth() - 1);

    const inputDate = new Date(dateString);
    return inputDate >= oneMonthAgo && inputDate <= today;
  };

  const updateStatus = (activeLead) => {
    const { email, status, source } = activeLead || {};
    setLoadingLeads(
      (prevLoadingLeads) => new Set([...prevLoadingLeads, email])
    );
    axios
      .put(
        "/crm",
        {
          email,
          status,
          source
        },
        { headers: { "x-api-key": process.env.REACT_APP_X_API_KEY_CRM || "" } }
      ).then(
        async () => await leadsMutate()
      )
      .catch((err) => {
        const error =
          err?.response?.data?.message || err?.response?.data?.error;
        if (error) toast.error(error);
        else toast.error("Something went wrong!");
      })
      .finally(() =>
        setLoadingLeads(
          (prevLoadingLeads) =>
            new Set([...prevLoadingLeads].filter((e) => e !== email))
        )
      );
  };

  const handleSync = async () => {
    const socket = io(`${apiGateway}/crm`);
    socket.on("connect", () => {


      socket.on("sync-update", (data) => {
        if (data?.progress) toast.success(data.progress);
      });
    });

    axios
      .post("/crm/sync", { source: filters.source }, {
        headers: { "x-api-key": process.env.REACT_APP_X_API_KEY_CRM || "" },
      })
      .then((res) => {
        if (res.data?.message) {
          toast.success("Syncing Successful");
        }
      })
      .catch((err) => {
        const error =
          err?.response?.data?.message || err?.response?.data?.error;
        if (error) toast.error(error);
        else toast.error("Something went wrong!");
      })
      .finally(() => {
        socket.disconnect();
        console.log("Socket connection disconnected");
      });
  };
  const [selectedTab, setSelectedTab] = useState("All Leads");

  const handleStatusChange = (value) => {
    setFilters((prev) => ({ ...prev, status: value }));
  };

  const handleSourceChange = (value) => {
    setFilters((prev) => ({ ...prev, source: value }));
  };
  const sources = [
    { id: 'rabbito', title: 'Rabbito' },
    { id: 'objex', title: 'Objex' },

  ];
  const [selectedColumn, setSelectedColumn] = useState({ subscription: "Leads" });
  const handleButtonClick = (itemTitle) => {
    if (selectedColumn.subscription !== itemTitle) {
      setSelectedColumn({ subscription: itemTitle });
    }
  };
  const subscriptionOptions = [
    { name: "Standard", value: "standard" },
    { name: "Professional", value: "pro" },
    { name: "Enterprise", value: "enterprise" },
    { name: "Manager", value: "manager" },
  ];

  const handleFilterChange = (value) => {
    setFilters((prev) => ({
      ...prev,
      subscription: prev.subscription === value ? "" : value,
    }));
  };
  const tabs = ["All Leads", "Hosted", "Abandoned"];
  const handleDateChange = (value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      dateRange: value,
    }));
    console.log("Selected date range:", value);
  };


  return (
    <SidebarLayout>

      <div className="px-3 md:px-6 lg:px-8 py-2 md:py-4 lg:py-6 overflow-x-auto no-scrollbar overflow-y-hidden">
        <div className="flex flex-row  items-center mt-3 mb-4  space-y-0">
          <h2 className="md:text-3xl text-xl font-medium leading-9 text-heading-1">
            Leads
          </h2>
          <div>

          </div>
          <div className="flex md:justify-between justify-end gap-2 w-full">
            <div className="md:hidden block">
              <DropDown
                selectedFilter={
                  subscriptionOptions.find(option => option.value === filters.subscription)?.name || "ALL"
                }
                options={subscriptionOptions.map(option => option.name)}
                onFilterChange={(name) => {
                  const selectedValue = subscriptionOptions.find(option => option.name === name)?.value || "";
                  handleFilterChange(selectedValue);
                }}
              />
            </div>

            <div className="md:flex hidden flex-wrap ml-6 gap-3">
              {[
                { name: "Standard", value: "standard" },
                { name: "Professional", value: "pro" },
                { name: "Enterprise", value: "enterprise" },
                { name: "Manager", value: "manager" },
              ].map((item, index) => (
                <button
                  key={`${item.name}__${item.value}__${index}`}
                  onClick={() =>
                    setFilters((prev) => ({
                      ...prev,
                      subscription:
                        prev.subscription === item.value ? "" : item.value,
                    }))
                  }
                  className={`text-xs text-heading-2 border border-border  rounded-md px-2 py-1.5 bg-background-1 duration-200 ${item.value === filters.subscription
                    ? "bg-primary text-white"
                    : "border-border"
                    }`}
                >
                  {item.name}
                </button>
              ))}
              <Transition
                show={filters.subscription ? true : false}
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-x-1"
                enterTo="opacity-100 translate-x-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-x-0"
                leaveTo="opacity-0 translate-x-1"
              >
                <button
                  onClick={() =>
                    setFilters((prev) => ({ ...prev, subscription: "" }))
                  }
                  className="text-sm text-primary"
                >
                  Clear Filters
                </button>
              </Transition>
            </div>



            <div className="flex items-center gap-2">
              {/* Export Leads Button */}
              <Button className="px-1 py-2 hidden sm:flex" onClick={downloadCSV}>
                <FiDownload className="mr-2 h-5 w-5" />
                Export Leads
              </Button>
              <Button className="px-1 py-2 sm:hidden flex items-center gap-2" onClick={downloadCSV}>
                <FiDownload className="h-5 w-5" />
              </Button>


              <label className="bg-primary text-white px-2.5 py-[7px] rounded-md whitespace-nowrap flex items-center gap-2 cursor-pointer hidden sm:flex">
                <FiUpload className="h-5 w-5" />
                Import Leads
                <input
                  type="file"
                  id="csv-upload"
                  accept=".csv"
                  className="hidden"
                  ref={fileInputRef}
                  onChange={handleFileUpload}
                />
              </label>
              <label className="bg-primary text-white px-4 py-[9px] rounded-md whitespace-nowrap flex items-center gap-2 cursor-pointer sm:hidden">
                <FiUpload className="h-5 w-5" />
                <input
                  type="file"
                  id="csv-upload"
                  accept=".csv"
                  className="hidden"
                  ref={fileInputRef}
                  onChange={handleFileUpload}
                />
              </label>


              <div className="relative">
                <Menu as="div" className="relative inline-block text-left">
                  {({ open }) => (
                    <>
                      <Menu.Button className="flex items-center justify-center p-2 border border-[#555555] rounded-md hover:border-gray-300">
                        <BsSliders className="w-7 h-5 text-heading-1" />
                      </Menu.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 top-12 w-40 origin-top-right divide-y divide-border-border-1 rounded-md bg-background-1 shadow-lg border border-border-1 z-40">
                          <div className="py-2  ">
                            {/* Status Listbox */}
                            <div className="md:block hidden">
                              <ListBox
                                value={filters.status}
                                options={boardColumns}
                                onChange={handleStatusChange}
                                label="Status"
                              />
                            </div>
                            <ListBox
                              value={filters.source}
                              options={sources}
                              onChange={handleSourceChange}
                              label="Source"
                            />
                            <ListBox
                              label="Date Range"
                              value={filters.dateRange}
                              onChange={(value) => handleDateChange(value)}
                              options={[
                                { id: 'today', title: 'Today' },
                                { id: 'thisWeek', title: 'This Week' },
                                { id: 'lastWeek', title: 'Last Week' },
                                { id: 'thisMonth', title: 'This Month' },
                                { id: 'lastMonth', title: 'Last Month' },
                                { id: 'thisYear', title: 'This Year' },
                                { id: 'lastYear', title: 'Last Year' },
                              ]}
                            />
                          </div>
                        </Menu.Items>
                      </Transition>
                    </>
                  )}
                </Menu>
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between">
          <div className="md:flex items-center gap-4 hidden ">
            {tabs.map((tab) => (
              <button
                key={tab}
                onClick={() => setSelectedTab(tab)}
                className={` ${selectedTab === tab ? " text-secondary-txt border-secondary-txt pb-1 border-b-2 " : "text-secondary-txt-1"
                  }`}
              >
                {tab}
              </button>
            ))}
          </div>
          <div className="flex items-center w-full mb-5  md:ml-4 border border-border rounded-lg md:w-[30%]  relative ">
            <label htmlFor="search" className="absolute  left-2">
              <IoSearchOutline className="h-5 w-5 text-heading-1" />
            </label>
            <input
              name="search"
              type="search"
              placeholder="Search"
              defaultValue={search || ""}
              className="md:ml-6 ml-8 text-sm flex-1 bg-transparent w-full text-placeholder md:px-3 md:py-2 py-1.5 placeholder-placeholder sm:text-sm  focus:outline-none focus:border-primary focus:ring-primary"
              onChange={e => debouncedSetSearch(e.target.value)}
            />
          </div>
        </div>
        <div className="flex md:hidden gap-2  ">
          {[
            { name: "Leads", title: "Leads" },
            { name: "QUALIFIED", title: "Qualified" },
            { name: "ENGAGED", title: "Discovery" },
            { name: "SALE", title: "Hosted" },
            { name: "ABANDONED", title: "Abandoned" },
          ].map((item, index) => (
            <button
              disabled={item.title === filters.subscription}
              key={`${item.name}__${item.value}__${index}`}
              onClick={() => handleButtonClick(item.title)}
              className={`text-xs text-heading-2 px-1 py-1.5 duration-200 ease-in-out transform ${item.title === selectedColumn.subscription
                ? "border-b-2 border-background-3 text-secondary-txt scale-105"
                : "text-secondary-focus"
                } transition-all`}

            >
              {item.name}
            </button>
          ))}


        </div>

        <div className="flex items-center mt-4">
          <DndContext
            sensors={sensors}
            onDragEnd={onDragEnd}
            onDragOver={onDragOver}
            onDragStart={(e) => setActiveLead(e.active.data.current.lead)}
          >
            {/* Board Columns */}
            {!allLeads && !leadsError ? (
              <Skeleton type="board" />
            ) : device.mobile || device.tab ? (

              <Swiper
                slidesPerView={device.tab ? 2 : 1}
                spaceBetween={device.tab ? 20 : 0}
                pagination={{ clickable: true }}
                modules={[Pagination]}
              >
                <SwiperSlide key="col.id">
                  {boardColumns
                    .filter((col) => col.title === selectedColumn.subscription)
                    .map((col) => (
                      <Column
                        selectedTab={selectedTab}
                        key={col.id}
                        column={col}
                        leads={leads.filter((lead) => lead.status === col.id)}
                        loadingLeads={loadingLeads}
                        leadsMutate={leadsMutate}
                        source={filters.source}
                      />
                    ))}
                </SwiperSlide>
              </Swiper>
            ) : (
              <div className={`grid grid-cols-4 gap-4  w-full`}>
                {boardColumns
                  .filter((col) => {
                    if (selectedTab === "All Leads") return col.id !== "ABANDONED";
                    if (selectedTab === "Hosted") return col.id === "SALE";
                    if (selectedTab === "Abandoned") return col.id === "ABANDONED";
                    return true;
                  })
                  .map((col) => (
                    <Column
                      selectedTab={selectedTab}
                      key={col.id}
                      column={col}
                      source={filters.source}
                      leads={leads.filter((lead) =>
                        lead.status === col.id &&
                        ((selectedTab === "All Leads" && col.id === "SALE")
                          ? isWithinLastMonth(lead.updated_at)
                          : true)
                      )}
                      loadingLeads={loadingLeads}
                      leadsMutate={leadsMutate}
                    />
                  ))}



                {createPortal(
                  <DragOverlay>
                    {activeLead && (
                      <LeadCard
                        lead={activeLead}
                        loadingLeads={loadingLeads}
                        leadsMutate={leadsMutate}
                      />
                    )}

                  </DragOverlay>,
                  document.body
                )}

              </div>
            )}
          </DndContext>
        </div>

      </div>
    </SidebarLayout>
  );
}

export default Sales;

// Columns
const Column = ({ column, leads, loadingLeads, leadsMutate, selectedTab, source }) => {
  const totalLeads = leads.length;
  const [isOpenNewLead, setOpenNewLeadPopup] = useState(false);
  const [sortDirection, setSortDirection] = useState("asc");
  const sortedLeads = useMemo(() => {
    const sorted = [...leads].sort((a, b) => {
      const dateA = new Date(a.joiningDate);
      const dateB = new Date(b.joiningDate);
      return sortDirection === "asc"
        ? dateA - dateB
        : dateB - dateA;
    });
    return sorted;
  }, [leads, sortDirection]);
  const handleSortToggle = () => {
    setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"));
  };

  const leadIds = useMemo(() => {
    return leads.map((lead, index) => index);
  }, [leads]);
  const { setNodeRef, transform, transition } = useSortable({
    id: column.id,
    data: {
      type: "Column",
      column,
    },
  });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };
  const downloadCSV = () => {
    const csvData = leads.map(lead => ({
      'Full Name': lead.fullName,
      'Email': lead.email,
      'Phone': lead.phone,
      'Status': lead.status,
    }));
    const csvString = Papa.unparse(csvData);
    const downloadLink = document.createElement('a');
    downloadLink.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvString);
    downloadLink.target = '_blank';
    downloadLink.download = `${column.title}_leads.csv`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className="bg-background-1  w-full lg:w-auto h-[75vh] flex flex-grow flex-col rounded-lg px-2"
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <h2 className="leading-normal text-sm font-normal py-2.5 text-heading-1">
            {column.id === "SALE" && selectedTab === "All Leads" ? "WIN" : column.title}
          </h2>
          <button
            onClick={handleSortToggle}
            className="text-heading-1 text-sm font-semibold flex items-center gap-1"
          >
            {sortDirection === "asc" ? <FaSort className="text-gray-500" /> : <FaSort />}
          </button>
          <div className="bg-[#BDBDBD]  w-6 h-6 text-xs rounded-full flex justify-center items-center dark:text-black">{totalLeads}</div>
        </div>
        <div className="flex items-center gap-2">
          <button
            onClick={downloadCSV}
            className="text-heading-1 text-sm font-semibold px-1 bg-background-primary leading-normal flex gap-2 items-center rounded-md py-1"
          >
            <FiDownload className="text-primary h-5 w-5" />
          </button>
          <button
            onClick={() => setOpenNewLeadPopup(true)}
            className="text-heading-1 text-sm px-1 bg-background-primary font-semibold leading-normal flex gap-2 items-center rounded-md py-1"
          >
            <FaPlus className="text-primary h-5 w-5" />
          </button>

        </div>

      </div>

      <div className="flex flex-grow  flex-col gap-4 overflow-x-hidden overflow-y-auto no-scrollbar w-full">
        <SortableContext items={leadIds}>
          {sortedLeads.map((lead) => (
            <LeadCard
              key={lead.email}
              lead={lead}
              email={lead?.email}
              loadingLeads={loadingLeads}
              leadsMutate={leadsMutate}
            />
          ))}
        </SortableContext>
      </div>
      <AddNewLead
        source={source}
        isOpen={isOpenNewLead}
        leadsMutate={leadsMutate}
        status={column?.id}
        close={() => setOpenNewLeadPopup(false)}
      />
    </div>
  );
};
