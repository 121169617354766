import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import Loading from "./components/Loading";
import useAuth from "./hooks/useAuth";

const AuthCheck = ({ children }) => {
  const location = useLocation();
  const { user, loading } = useAuth();

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <Loading width="12" height="12" />
      </div>
    );
  }

  if (!user?.email) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
};

export default AuthCheck;
