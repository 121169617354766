import React, { Fragment, useState } from "react";
import c from "classnames";
import { NavLink } from "react-router-dom";
import SidebarLayout from "./SidebarLayout";
import Button from "../components/Button";
import { useLocation } from "react-router-dom";
import { Listbox, Transition } from "@headlessui/react";
import { RiArrowDropDownLine } from "react-icons/ri";
import { targetAudience } from "../constants/targetAudience";
import axios from "axios";
import toast from "react-hot-toast";
import useSWR from "swr";



const MarketingLayout = ({
  children,
  openPromotionHandler,
  sendEmails,
  loading,
  selectedTemplate,
  setSelectedTemplate,
  selectedTargetAudience,
  setSelectedTargetAudience,
  lastSentMailInfo,
  setLastSentMailInfo,
  error,
  setError, }) => {
  const location = useLocation();
  const isMarketingPage = location.pathname.startsWith("/marketing/promotions");
  const isEmailPage = location.pathname.startsWith("/marketing/email");
  const links = [
    {
      href: "/marketing/promotions",
      name: "Promotions",
    },
    {
      href: "/marketing/email",
      name: "Email",
    },
  ];



  return (
    <SidebarLayout>
      <div className="px-3 md:px-6 lg:px-8 lg:py-6 overflow-x-auto overflow-y-auto no-scrollbar ">
        <div className="flex justify-between items-center mt-3 md:mt-0 ">
          <h2 className="md:text-3xl text-lg font-medium  text-heading-1">
            Marketing
          </h2>
          {isMarketingPage && (
            <Button className={"mt-1"} onClick={openPromotionHandler}>
              <span>New Promotion</span>
            </Button>
          )}

          {isEmailPage && (
            <div className="flex items-center gap-1">

              <Button
                className="whitespace-nowrap"
                onClick={sendEmails}
                loading={loading}
                disabled={
                  !selectedTemplate?.id || !selectedTargetAudience.segmentId ||
                  loading ||
                  (lastSentMailInfo.segmentId === selectedTargetAudience.segmentId &&
                    lastSentMailInfo.templateId === selectedTemplate?.id)
                }
              >
                Send Email
              </Button>
              <Listbox
                value={selectedTargetAudience.segmentId}
                onChange={(e) => {
                  error && setError("");
                  setSelectedTargetAudience(e);
                }}
                disabled={loading}
              >
                <div className="relative">
                  <Listbox.Button className="flex justify-between items-center relative w-[8rem] md:w-[9.5rem] rounded-md bg-transparent border border-border px-2 py-1.5 focus:outline-none focus:ring-1 focus:ring-offset-1 ring-primary disabled:cursor-not-allowed">
                    <span className="text-heading-2 whitespace-nowrap text-xs font-normal md:text-sm md:font-medium">
                      {selectedTargetAudience.name || "Target Audience"}
                    </span>
                    <RiArrowDropDownLine className="w-6 h-6 text-heading-2" />
                  </Listbox.Button>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute right-0 top-[40px] w-[8rem] md:w-[9.5rem] origin-top-right divide-y divide-border-border-1 rounded-md bg-background-4 shadow-lg focus:outline-none border border-border-1">
                      <div className="px-1 py-1">
                        {targetAudience.map((audience, index) => (
                          <Listbox.Option
                            key={`${audience.name}__${index}`}
                            className={({ active }) =>
                              `group flex gap-1 w-full items-center hover:bg-background-3 hover:text-white rounded-md px-2 py-2 text-sm cursor-pointer 
                     ` }
                            value={audience}
                          >
                            {({ selected }) => (
                              <>
                                <span
                                  className={`block truncate `}
                                >
                                  {audience.name}
                                </span>
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </div>
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>
            </div>
          )}

        </div>

        <div className="flex overflow-hidden flex-row  w-min">
          {links.map((link, index) => (
            <NavLink
              to={link.href}
              key={`${link.href}__${link.name}__${index}`}
              className="w-full group text-sm font-medium"
            >
              {({ isActive, isPending, ...props }) => (
                <button
                  {...props}
                  to={link.href}
                  className={c(
                    "cursor-pointer flex items-center whitespace-nowrap justify-center py-2 px-4 text-lg font-medium ",
                    isActive ? "text-heading-1 text-lg border-b-2 border-purple-500 font-semibold" : "bg-transparent text-secondary-disabled-txt duration-100"
                  )}
                >
                  {link.name}
                </button>
              )}
            </NavLink>
          ))}
        </div>
        {children}
      </div>
    </SidebarLayout>
  );
};

export default MarketingLayout;
