export const staggerContainer = ( staggerChildren = 0.5, delayChildren = 0.5 ) => (     
    {
        hidden: { opacity: 0 },
        show: {
          opacity: 1,
          transition: {
            staggerChildren,
            delayChildren
          }
        }
      }
    
)

export const zoomIn = ( initialScale = 0.5, finalScale = 1 ) =>(
    {
        initial: { scale: initialScale },
        final: {
            scale: finalScale,
            opacity: 1,
            transition : {
                duration: 2,
              }
        }
      }
)

export const fadeIn = ( duration = 2 ) =>(
    {
        initial: { opacity:0 },
        final: {
            opacity: 1,
            transition : {
                duration: duration,
              }
        }
      }
)

export const fadeInDirection = (direction, type, delay, duration) => ({
    hidden: {
      x: direction === 'left' ? 100 : direction === 'right' ? -100 : 0,
      y: direction === 'up' ? 100 : direction === 'down' ? -100 : 0,
      opacity: 0,
    },
    show: {
      x: 0,
      y: 0,
      opacity: 1,
      transition: {
        type,
        delay,
        duration,
        ease: 'easeOut',
      },
    },
  });

export const menuItemVariants = {
    initial: {
        opacity: 0
    },
    opened: {
        opacity: 1,
        transition: {
            duration: .9,
            ease: 'easeIn'
        }
    },
    closed: {
        opacity: 0,
        transition: {
            duration: .9,
            ease: [.1, 1, .57, 1]
        }
    }
}

export const menuIconVarints = {
    opened: (i) => ({
        x: [0, i * 2.4, 0],
    }),
    closed: (i) => ({
        x: [0, -i * 2.4, 0],
    })
}